<template>
  <v-overlay z-index='99999' absolute :value='isLoading'>
    <v-progress-circular indeterminate size='64' />
  </v-overlay>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'Loading',
    computed: {
      ...mapGetters('loading', [
        'isLoading',
      ]),
    },
  };
</script>
